<template>
  <div class="home" style="position: relative">
    <top-fixed></top-fixed>
    <div style="background-color: #1d76fb; padding: 0 0.25rem 0.5rem">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in bannerList"
            :key="index"
          >
            <img
              @click="gouUrl(item)"
              style="width: 7rem; height: 2.9rem"
              :src="item.imgurl"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <div
      style="
        background-color: #fff;
        border-radius: 10px;
        margin-top: -0.2rem;
        padding: 0.25rem;
        line-height: 1.5em;
        padding-bottom: 3rem;
      "
    >
      <div style="text-align: center">
        <img
          style="width: 7.5rem; height: 3.75rem"
          src="../assets/img/banner03.jpg"
          alt=""
        />
      </div>
      <div style="color: #bfbfbf; text-indent: 20px; text-align: justify">
        1990年代的乙太網路網卡或叫NlC(Network.InterfaceCard乙太網路配接器)。這張卡可以支援基於同軸電的10BASE2(BNC速接器，左)和基於絞線的10BASE-T(RJ-45，右)。
      </div>
      <div
        style="
          text-indent: 20px;
          text-align: justify;
          padding-top: 0.2rem;
          line-height: 0.5rem;
        "
      >
        乙太網路實作了網路上無線電系統多個節點傳送資訊的想法，每個節點必須取得電續或者通道才能傳送资訊，有時也叫作以太(Ether)。這個名字來源於19世紀的物理學家假設的電磁輻射媒--光以太。每一個節點有全球唯一的48位元位址也就是製造商分配給網卡的MAC位址，以保證Z太網路上所有節點能互相鑑别。由於乙太網路十分普遍，许多製造商把乙太網路卡直接整合進電腦主機板。
      </div>
    </div>
    <a
      class="kefu"
      :href="kf_url"
      style="position: fixed; top: 80%; z-index: 1000; width: 100px; left: 80%"
    >
      <div
        id="fixedbutton"
        class="float_cs_btn"
        style="
          background: #f3e4cd;
          box-shadow: 0 2px 10px #0000001a;
          border-radius: 50%;
          color: #121212;
          z-index: 98;
          transition: all 0.3s;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: fixed;
          bottom: 20vw;
          width: 1.2rem;
          height: 1.2rem;
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          class="iconify iconify--streamline"
          width="0.8rem"
          height="0.8rem"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 14 14"
          data-icon="streamline:customer-support-1"
        >
          <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 7V4.37A3.93 3.93 0 0 1 7 .5a3.93 3.93 0 0 1 4 3.87V7M1.5 5.5h1A.5.5 0 0 1 3 6v3a.5.5 0 0 1-.5.5h-1a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1m11 4h-1A.5.5 0 0 1 11 9V6a.5.5 0 0 1 .5-.5h1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1M9 12.25a2 2 0 0 0 2-2V8m-2 4.25a1.25 1.25 0 0 1-1.25 1.25h-1.5a1.25 1.25 0 0 1 0-2.5h1.5A1.25 1.25 0 0 1 9 12.25"
          ></path>
        </svg>
      </div>
    </a>
    <bottom-fixed></bottom-fixed>
    <div
      v-if="noticeShow"
      style="
        position: fixed;
        width: 300px;
        height: 250px;
        top: -10px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 1000;
        padding: 0 10px;
        background: #fff;
        border-radius: 20px;
      "
    >
      <img
        @click="showUserNotic"
        src="../assets/img/X.png"
        style="
          position: absolute;
          width: 20px;
          height: 20px;
          top: -30px;
          left: 300px;
        "
      />
      <div
        class="swiper-container"
        style="
          overflow: auto;
          height: 180px;
          margin-top: 10px;
          background: #fff;
        "
      >
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in noticeData"
            :key="index"
          >
            <img style="width: 300px" :src="item.imgurl" />
            <div
              v-html="item.content"
              style="text-align: center; margin: 10px"
            ></div>
          </div>
        </div>
      </div>

      <div
        @click="showUserNotic"
        style="
          border: 1px solid #2d8cf0;
          text-align: center;
          color: #2d8cf0;
          width: 150px;
          line-height: 32px;
          margin: 0 auto;
          border-radius: 10px;
          margin-top: 10px;
        "
      >
        Close
      </div>
    </div>

    <div
      v-if="noticeShows"
      style="
        position: fixed;
        width: 300px;
        height: 250px;
        top: -10px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 1000;
        padding: 0 10px;
        background: #fff;
        border-radius: 20px;
      "
    >
      <img
        @click="noticeShows = false"
        src="../assets/img/X.png"
        style="
          position: absolute;
          width: 20px;
          height: 20px;
          top: -30px;
          left: 300px;
        "
      />
      <div
        style="
          overflow: auto;
          height: 180px;
          margin-top: 10px;
          background: #fff;
          display: flow;
        "
      >
        <div>
          <div
            v-html="noticeDatas.content"
            style="text-align: center; margin: 10px"
          ></div>
        </div>
      </div>

      <div
        @click="toActive"
        style="
          border: 1px solid #2d8cf0;
          text-align: center;
          color: #2d8cf0;
          width: 150px;
          line-height: 32px;
          margin: 0 auto;
          border-radius: 10px;
          margin-top: 10px;
        "
      >
        Check
      </div>
    </div>

    <div
      v-if="noticeShow || noticeShows"
      style="
        position: absolute;
        background: #969799;
        opacity: 0.6;
        width: 100%;
        height: 100%;
        top: 0;
      "
    ></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';
import { swipe, ethusdt, user_notice, user_notices, joinActivity, invitecode } from '../http/api'
import BottomFixed from '../components/BottomFixed.vue';
import TopFixed from '../components/TopFixed.vue';
export default {
  components: { BottomFixed, TopFixed },
  name: "HomeView",
  data () {
    return {
      bannerList: [],
      noticeData: '',
      noticeDatas: '',
      noticeShow: false,
      noticeShows: false,
      kf_url: ''
    }

  },
  mounted () {
    this.fetchBanner()
    this.dataInit()
    invitecode().then((res) => {
      this.kf_url = res.data.kf_url
    })
  },
  methods: {
    fetchBanner () {
      swipe().then(res => {
        if (res.data) {
          this.bannerList = res.data;
          setTimeout(() => {
            new Swiper(".swiper-container", {
              autoplay: true
            });
          }, 1000);
        }
      })
    },
    dataInit () {
      ethusdt().then((res) => {
        window.localStorage.setItem("exchange_rate", res.data.exchange_rate)
      })
      user_notice().then((res) => {
        if (res.code != 401 && (res.code == 1 && res.data.length != 0)) {
          this.noticeShow = true
          this.noticeData = res.data
        }
      })
    },

    showUserNotic () {
      this.noticeShow = false
      user_notices().then((res) => {
        if (res.code != 401 && (res.code == 1 && res.data.length != 0)) {
          this.noticeShows = true
          this.noticeDatas = res.data
        }
      })
    },

    toActive () {
      joinActivity({ activity_id: this.noticeDatas.act_id }).then((res) => {
        this.$router.push({
          path: "/activitDea",
          query: {
            id: this.noticeDatas.act_id
          }
        })
      })
    },

    gouUrl (item) {
      window.open(item.url)
    }
  }
};
</script>

<style >
.swiper-container {
  /* width: 600px; */
  height: 3rem;
  background: #1d76fb;
}
.home {
  overflow: hidden;
}
</style>
