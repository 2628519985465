<template>
  <router-view></router-view>
</template>

<script>

import checkReg from './assets/js/main.js'
export default {
  name: 'app',
  data () {
    return {
      status: 0,
      activityShow: false,
      interval: null,
    };
  },
  methods: {
    close () {
      this.status = 1
    }
  },
  created () {
    checkReg()
  }
}
</script>

<style>
@import url("./assets/global.css");
body {
  background: #fff;
}
</style>
