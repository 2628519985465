import Vue from 'vue';
import VueI18n from 'vue-i18n';
import zh from './zh.js';
import en from './en.js';

Vue.use(VueI18n);

const DEFAULT_LANG = 'zh_TW';
const LOCALE_KEY = 'language';

const locales = {
  en: {
    ...en
  },
  zh_TW: {
    ...zh
  }
};
// en-us  zh-cn
// let langLocale = getCookie(LOCALE_KEY) || 'en';
// if (!window.localStorage.getItem(LOCALE_KEY)) {
//   window.localStorage.setItem(LOCALE_KEY, DEFAULT_LANG);
// }
// let langLocale = window.localStorage.getItem(LOCALE_KEY)
//   ? window.localStorage.getItem(LOCALE_KEY)
//   : 'zh_CN';
let langLocale = 'zh_TW';
window.localStorage.setItem(LOCALE_KEY, 'zh_TW');
const i18n = new VueI18n({
  locale: 'zh_TW',
  messages: locales,
  silentTranslationWarn: true
});
const init = Vue.prototype._init;
Vue.prototype._init = function(options) {
  init.call(this, {
    i18n,
    ...options
  });
};

export const setup = lang => {
  if (lang === undefined) {
    // lang = window.localStorage.getItem(LOCALE_KEY);
    // const language = getCookie(LOCALE_KEY);
    const language = window.localStorage.getItem(LOCALE_KEY);
    if (language) {
      langLocale = language.replace('-', '_').toLowerCase();
    }
    if (undefined !== langLocale && langLocale !== '') {
      lang = langLocale;
    }
    if (locales[lang] === undefined) {
      lang = DEFAULT_LANG;
    }
    //  (`lang-${lang}`);
  }

  Vue.config.lang = lang;
  i18n.locale = lang;
};

setup();

export default i18n;
