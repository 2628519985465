/* eslint-disable */
export default {
  hj1: 'Homepage',
  hj2: 'Balance',
  hj3: 'Exchanging',
  hj4: 'On chain balance',
  hj5: 'View Rewards',
  hj6: 'Withdrawal',
  hj7: 'Exchange',
  hj8: 'All',
  hj9: 'Activity Rewards',
  hj10: 'Exchange Rewards',
  hj11: 'Withdrawal Record',
  hj12: 'Audited',
  hj13: 'Withdrawal amount',
  hj14: 'Reward Quantity',
  hj15: 'Quantity',
  hj16: 'Exchange rate',
  hj17: 'Help Center',
  hj18: 'Choose Language',
  hj19: 'Participated',
  hj20: 'In Progress',
  hj21: 'Claim',
  hj22: 'Account',
  hj23: 'Activity',
  hj24: 'Revenue Record',
  hj25: 'White Paper',
  hj26: 'Help Center',
  hj27: 'Choose Language',

  hj28: 'Total revenue',
  hj29: 'You will accept Gundam',
  hj30: 'Reward',
  hj31: 'Rules',
  hj32: 'End',
  hj33: 'Reward Standards',
  hj34: 'Contract amount',
  hj35: 'Explanation',
  hj36: 'Total reward',
  hj37: 'Contract Rewards'
};
