import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
//首页

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/AccountView.vue')
  },
  {
    path: '/reward',
    name: 'reward',
    component: () => import('../views/ActivityView.vue')
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('../views/RewordView.vue')
  },
  {
    path: '/language',
    name: 'language',
    component: () => import('../views/LanguageView.vue')
  },

  {
    path: '/whitebook',
    name: 'whitebook',
    component: () => import('../views/WhitebookView.vue')
  },

  {
    path: '/help',
    name: 'help',
    component: () => import('../views/HelpView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/activitDea',
    name: 'activitDea',
    component: () => import('../views/ActivitDea.vue')
  }
];

//生成路由实例
const router = new VueRouter({
  routes
});
// router.beforeEach((to, from, next) => {
//   //修改标题路由配置加上这个
//   document.title = to.matched[0].meta.title;
//   next();
// });

export default router;
