<template>
  <div class="fixed-bottom">
    <div @click="toBar('/')">
      <!-- {{ $router?.currentRoute }} -->
      <img v-if="nowBar == 1" src="../assets/img/tab05.png" alt="" />
      <img v-else src="../assets/img/tab01.png" alt="" />
    </div>
    <div @click="toBar('/account')">
      <img v-if="nowBar == 2" src="../assets/img/tab06.png" alt="" />
      <img v-else src="../assets/img/tab02.png" alt="" />
    </div>
    <div @click="toBar('/activity')">
      <img v-if="nowBar == 3" src="../assets/img/tab07.png" alt="" />
      <img v-else src="../assets/img/tab03.png" alt="" />
    </div>
    <div @click="toBar('/reward')">
      <img v-if="nowBar == 4" src="../assets/img/tab08.png" alt="" />
      <img v-else src="../assets/img/tab04.png" alt="" />
    </div>
  </div>
</template>

<script>


export default ({
  data () {
    return {
      nowBar: 1
    }
  },
  created () {
    console.log(this.$route.path)
    switch (this.$route.path) {
      case '/':
        this.nowBar = 1
        break
      case '/account':
        this.nowBar = 2
        break
      case '/activity':
        this.nowBar = 3
        break
      case '/reward':
        this.nowBar = 4
        break
    }
  },
  methods: {
    toBar (value,) {
      this.$router.push(value)
    }
  }
})
</script>


<style  scoped>
.fixed-bottom {
  position: fixed;
  bottom: 0;
  height: 1.1rem;
  display: flex;
  width: 7.5rem;
  background: #fff;
}

.fixed-bottom div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fixed-bottom div img {
  width: 0.5rem;
  height: 0.5rem;
}
</style>