<template>
  <div>
    <!-- background-color: #1d76fb; -->
    <div
      style="
        padding: 0.25rem;
        height: 0.6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #1d76fb;
      "
    >
      <div style="height: 0.55rem">
        <img style="height: 0.55rem" src="../assets/img/logo.png" alt="" />
      </div>
      <div @click="show = true" style="height: 0.35rem">
        <img style="height: 0.35rem" src="../assets/img/menu.png" alt="" />
      </div>
    </div>

    <div
      v-if="show"
      @click="show = false"
      style="
        background-color: rgba(0, 0, 0, 0.6);
        position: fixed;
        width: 7.5rem;
        height: 100vh;
        z-index: 99;
        top: 0;
        color: #fff;
      "
    >
      <div
        style="
          display: flex;
          height: 1.2rem;
          padding: 0 0.25rem;
          align-items: center;
        "
        @click="$router.push('/')"
      >
        <img
          style="width: 0.3rem; height: 0.3rem"
          src="../assets/img/a_01.png"
          alt=""
        />
        <div
          style="
            border-bottom: 1px solid #fff;
            flex: 1;
            margin-left: 0.25rem;
            line-height: 1.2rem;
          "
        >
          {{ $t("hj1") }}
        </div>
      </div>
      <div
        style="
          display: flex;
          height: 1.2rem;
          padding: 0 0.25rem;
          align-items: center;
        "
        @click="$router.push('/account')"
      >
        <img
          style="width: 0.3rem; height: 0.3rem"
          src="../assets/img/a_02.png"
          alt=""
        />
        <div
          style="
            border-bottom: 1px solid #fff;
            flex: 1;
            margin-left: 0.25rem;
            line-height: 1.2rem;
          "
        >
          {{ $t("hj22") }}
        </div>
      </div>
      <div
        style="
          display: flex;
          height: 1.2rem;
          padding: 0 0.25rem;
          align-items: center;
        "
        @click="$router.push('/activity')"
      >
        <img
          style="width: 0.3rem; height: 0.3rem"
          src="../assets/img/a_03.png"
          alt=""
        />
        <div
          style="
            border-bottom: 1px solid #fff;
            flex: 1;
            margin-left: 0.25rem;
            line-height: 1.2rem;
          "
        >
          {{ $t("hj23") }}
        </div>
      </div>
      <div
        style="
          display: flex;
          height: 1.2rem;
          padding: 0 0.25rem;
          align-items: center;
        "
        @click="$router.push('/reward')"
      >
        <img
          style="width: 0.3rem; height: 0.3rem"
          src="../assets/img/a_04.png"
          alt=""
        />
        <div
          style="
            border-bottom: 1px solid #fff;
            flex: 1;
            margin-left: 0.25rem;
            line-height: 1.2rem;
          "
        >
          {{ $t("hj24") }}
        </div>
      </div>
      <div
        style="
          display: flex;
          height: 1.2rem;
          padding: 0 0.25rem;
          align-items: center;
        "
        @click="$router.push('/whitebook')"
      >
        <img
          style="width: 0.3rem; height: 0.3rem"
          src="../assets/img/a_05.png"
          alt=""
        />
        <div
          style="
            border-bottom: 1px solid #fff;
            flex: 1;
            margin-left: 0.25rem;
            line-height: 1.2rem;
          "
        >
          {{ $t("hj25") }}
        </div>
      </div>
      <div
        style="
          display: flex;
          height: 1.2rem;
          padding: 0 0.25rem;
          align-items: center;
        "
        @click="$router.push('/help')"
      >
        <img
          style="width: 0.3rem; height: 0.3rem"
          src="../assets/img/a_06.png"
          alt=""
        />
        <div
          style="
            border-bottom: 1px solid #fff;
            flex: 1;
            margin-left: 0.25rem;
            line-height: 1.2rem;
          "
        >
          {{ $t("hj26") }}
        </div>
      </div>
      <div
        style="
          display: flex;
          height: 1.2rem;
          padding: 0 0.25rem;
          align-items: center;
        "
        @click="$router.push('/language')"
      >
        <img
          style="width: 0.3rem; height: 0.3rem"
          src="../assets/img/a_07.png"
          alt=""
        />
        <div
          style="
            border-bottom: 1px solid #fff;
            flex: 1;
            margin-left: 0.25rem;
            line-height: 1.2rem;
          "
        >
          {{ $t("hj27") }}
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "HomeView",
  data () {
    return {
      show: false,
    };
  },
  methods: {
    // fetch_support () {
    //   this.$axios.get("/api/support").then(res => {
    //     console.log(res)
    //   })
    // },
    // fetch_check_reg () {
    //   this.$axios.post("/api/user/check_reg", {
    //     chain: "eth",
    //     user_address: "0xA00DcCc90e7F7f0c127F7cBaEb9B868E309Fa694"
    //   }).then(res => {
    //     console.log(res)
    //   })
    // }
  },

  mounted () {
    // this.fetch_support();
    // this.fetch_check_reg();

  },
};
</script>